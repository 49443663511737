.bnwpswrd-field, .bnwpswrd-field_{
    /* width: 50%; */
    width: 200px;
    flex-grow: 1;
    /* min-height: 60px; */
    height: fit-content;
    /* background-color: blue; */
    position: relative;
}

.text-danger{
    color: red;
    font-size: 14px;
    font-weight: 400;
}

@media screen and (max-width: 1050px) {
    .bnwpswrd-field_{
        margin-top: 10px;
    }
}
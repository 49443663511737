.sloadercontainer{
    width: 100%;
    height: calc(100vh - 100px);
    background-color: rgba(145, 145, 145, 0.699);
    display:flex;
    justify-content: center;
    align-items:center;
    position: relative;
}

.sloadercontainer_alt{
    width: 100%;
    height: 100%;
    background-color: rgba(145, 145, 145, 0.699);
    display:flex;
    justify-content: center;
    align-items:center;
    position: absolute;
    /* z-index: 200; */
}

.sloadingBox{
    width: 8%;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    /* height: 15%; */
}

#Loadingtag{
    color: #e50000;
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
    text-align: center;
}
.input_field{
    border: 1px solid rgba(136, 136, 136, 0.507);
}

.account-information{
    width: 100%;
    /* height: 150px; */
    padding-bottom: 15px;
    /* background-color: aqua; */
}

#icon-id{
    font-size: 25px;
    cursor: pointer;
    color: #010e31;
}

#row-appr-label{
    background-color: green;
    color: white;
    border: 2px solid white;
}

#row-declined-label{
    background-color: #ff2b0580;
    color: white;
    border: 2px solid white;
}

#row-notappr-label{
    background-color: orange;
    color: white;
    border: 2px solid white;
}

/******* Profile ************/
.booknwin-profile-container{
    width: 90%;
    /* min-height: 50vh; */
    height: fit-content;
    background-color: white;
    /* background-color: aqua; */
    display: flex;
    flex-direction: column
}

.bnw-claim-client-tab-container{
    width: 100%;
    display: flex;
    align-items: center;
    /* background-color: yellow; */
}

.bnw-claim-client-tab-container > div{
    width: 30%;
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}

.bnw-claim-client-tab-container > .active{
    border-top: 2px solid blue;
    background-color: #f9f9f9;
    border-bottom: 1px solid #f9f9f9;
}

.register-new-user, .changepass-new-user{
    width: 100%;
    height: 100%;
    padding: 20px;
    /* background-color: green; */
    display: flex;
    flex-direction: column;
}

.account-type-row{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 50px;
    padding: 10px;
}

.changepass-new-user > span{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 20px;
}

.register-new-user-tab{
    width: 100%;
    padding: 0px 0px;
    margin-bottom: 5px;
    /* background-color: blue; */
    display: flex;
}

.register-new-user-tab > div:first-child{
    width: 30%;
    padding: 8px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 20px;
}

.register-new-user-tab > div:first-child > span{
    font-size: 14px;
    font-weight: 800;
}

.register-new-user-tab > div:nth-child(2){
    width: 70%;
    padding: 8px 0px;
}

#reg-text-field, #reg-submit-field, #reg-phone-field{
    width: 70%;
    min-width: 190px;
    padding: 5px 10px;
    outline: none;
    border: 1px solid rgba(136, 136, 136, 0.212);
    font-size: 13px;
    font-weight: 500;
}

.account_number_field{
    border: 1px solid rgba(136, 136, 136, 0.212);
}

#reg-phone-field{
    width: calc(70% - 80px);
    min-width: 110px;
}

#reg-submit-field{
    cursor: pointer;
    font-weight: 700;
    color: white;
    background-color: #e50000;
}

/*********** Pagnation ************/
.bnw-table-pgnate-contain{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    /* background-color: aqua; */
    margin-top: 10px;
}

.forgotpass-cont{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 90%;
}

.forgotpass-cont > span{
    font-size: 14px;
    color: blue;
    cursor: pointer;
    font-weight: 500;
}

.forgotpass-cont > span:hover{
    color: #e50000;
    text-decoration: underline;
}

#addbitn{
    color: white;
    background-color: #e50000;
    padding: 8px 15px;
    margin-right: 5px;
    border: none;
    outline: none;
    font-weight: 500;
    cursor: pointer;
}

#addbitn:hover{
    background-color: #e500009c;
}

/********** Dashboard Right **********/
.bnw-body-right-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 20px 10px 30px 10px;
    border-radius: 10px;
    width: 25%;
    flex-flow: column;
    /* max-height: 50vh; */
    /* height: fit-content; */
}

.drcont-dp-container{
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: greenyellow; */
    margin-bottom: 20px;
}

#drcont-dp-container-img{
    background: url('../Assets/avatar/bases.jpg') no-repeat;
    background-position: center; 
    background-repeat: no-repeat;
    background-size: cover; 
    /* height: 80px;
    width: 80px; */
    border-radius: 50%;
    height: 100%;
    width: 100%;
}

.bnw-profileeImg-container{
    height: 80px;
    width: 80px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.158);
    margin: 0px 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

#dash-icon{
    font-size: 20px;
    cursor: pointer;
}

#profile-name, #profile-id-contain > #profile-id{
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
}

#profile-id, #profile-id-contain > #profile-id{
    font-size: 15px;
}

#profile-email{
    font-size: 16px;
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
    white-space: pre-wrap;
    word-wrap: break-word;
}

#profile-id-contain, #claim-now-buttn{
    width: 100%;
    display: flex;
    /* margin-bottom: 10px; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: aqua; */
}

#claim-now-buttn > button{
   padding: 8px 10px;
   background-color: green;
   color: white;
   border: none;
   border-radius: 3px; 
   cursor: pointer;
   font-size: 14px;
   font-weight: 500;
}

#claim-now-buttn > button:hover{
 background-color: rgba(5, 179, 5, 0.801);
}

/********** Login Page ********/

.signinContain{
    width: 100%;
    /* height: calc(100vh - 100px); */
    min-height: 100vh;
    min-width: 320px;
    background-color: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.bnw-login-contain{
    /* position: relative; */
    display: flex;
    flex-direction: column;
    /* min-width: 560px; */
    height: 100%;
}

.bnw-login-body{
    max-width: 350px;
    width: 90%;
    min-height: 450px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(41 41 41 / 36%) 0px 3px 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 30px 0px;
    gap: 10px;
}

.bnw-login-body > span:nth-child(2){
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}

.resetpass-bnw{
    width: 90%;
    margin-bottom: 15px;
}

.resetpass-bnw > span{
    font-size: 15px;
}

.bnw-doube-content-box{
    display: flex;
    align-items: center;
    width: 90%;
    height: fit-content;
    gap: 3px;
}

.bnw-ligin-email-container{
    width: 90%;
    height: fit-content;
    position: relative;
    display: flex;
    align-items: center;
}

.bnw-reg-phone-container{
    width: 50%;
    height: fit-content;
    position: relative;
    display: flex;
    align-items: center;
    /* background-color: blue; */
}

.bnw-ligin-email, .bnw-ligin-password{
    width: 90%;
    padding: 8px 10px;
    border: 1px solid rgba(0, 0, 0, 0.116);
    outline: none;
    font-weight: 500;
    /* margin-bottom: 20px; */
}

.errorpopup{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.errorpopup > span{
    font-size: 12px;
    color: red;
    font-weight: 500;
}

.bnw-ligin-password{
    width: 100%;
    position: relative;
    cursor: default;
}

.bnw-login-submit{
    background-color: #e50000;
    outline: none;
    border: 1px solid #e50000;
    color: white;
    width: 90%;
    min-width: 260px;
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 600;
    margin-top: 20px;
    cursor: pointer;
}

.bnw-lock-icon-container{
    background-color: #e50000;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    /* margin-bottom: 30px;
    margin-top: 30px; */
}

#bnw-lock-icon{
    font-size: 20px;
    color: white;
}

#visib-icon{
    font-size: 15px;
    position: absolute;
    top: 30%;
    right: 3%;
    color: #888;
    cursor: pointer;
}

#visib-icons{
    font-size: 15px;
    position: absolute;
    top: 35%;
    right: 32%;
    color: #888;
    cursor: pointer;
}

#reg-phone-drpdwn{
    width: 80px;
    padding: 5px 10px;
    border: 1px solid rgba(0, 0, 0, 0.116);
    outline: none;
    font-weight: 500;
    font-size: 13px;
}

/*********Table Filter Container**********/
.bnw-filter-tab-cntainer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.bnw-fcc{
    color: white;
    padding: 8px 10px;
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    cursor: pointer;
}

.tab1{
    background-color: rgba(136, 136, 136, 0.884);
}

.tab1:hover{
    background-color: rgba(110, 110, 110, 0.884);
}

.tab2{
    background-color: rgba(5, 199, 5, 0.623);
}

.tab2:hover{
    background-color: rgba(5, 199, 5, 0.808);
}

.tab3{
    background-color: #ffac0580;
}

.tab3:hover{
    background-color: #ffac05c2;
}

.tab4{
    background-color: #ff2b0580;
}

.tab4:hover{
    background-color: #ff2b05bb;
}

/********** Dashboard Page ********/
.bnwd-container{
    width: 100%;
    min-width: 320px;
    min-height: 100vh;
    background-color: #F7F8FA;
    display: flex;
    flex-direction: column;
}

.bnw-botm-container{
    width: 100%;
    height: fit-content;
    min-width: 320px;
    /* background-color: aqua; */
    display: flex;
    position: relative;
}


/****************Body Container****************/

.bnw-body-container, .bnw-promopg-container, .bnw-claim-container{
    width: 100%;
    height: 80vh;
    /* background-color: #e50000; */
    height: fit-content;
    display: flex;
    gap: 10px;
    /* overflow-y: auto; */
    padding: 10px;
    /* justify-content: center; */
    position: relative;
}

.bnw-body-left{
    width: 72%;
    /* min-height: 100vh; */
    height: fit-content;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.bnw-body-left-top{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 200px;
    gap: 10px;
    /* padding: 5px; */
}

.bnw-body-right{
    width: 25%;
    min-height: 100vh;
    /* background-color: rgb(217, 255, 0); */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.bnw-body-left-mid{
    display: flex;
    width: 100%;
    min-height: 400px;
    flex-wrap: wrap;
    flex-grow: 1;
    /* background-color: green; */
}

/***************Claim Container***************/

.bnw-claim-container{
    width: 100%;
    /* min-width: 560px; */
    min-height: 100vh;
    /* background-color: rgb(122, 165, 42); */
    padding: 20px;
    position: relative;
    flex-direction: column;

}

.bnw-claims-table-container{
    display: flex;
    flex-direction: column;
}

.bnw-claims-table{
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.089);
    height: 80vh;
    /* background-color: aqua; */
}

#bnw-claims-table-head{
    width: 100%;
    padding: 5px;
    background-color: #e50000;
}

#bnw-claims-table-head > tr > td{
    padding: 8px;
    /* font-size: 16px; */
    font-weight: 700;
    color: white;
    word-wrap: break-word;
}

td{
    padding: 5px;
    font-weight: 500;
    text-align: center;
    border: 0.5px solid rgba(0, 0, 0, 0.089);
    border-collapse: collapse;
    word-wrap: break-word;
}

#bnw-tab-status, #bnw-tab-status_1, #bnw-tab-status_2{
    padding: 5px 3px;
    border-radius: 10px;
    background-color: rgba(5, 199, 5, 0.623);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

#bnw-status-deact{
    padding: 5px 3px;
    width: 120px;
    border-radius: 10px;
    background-color: #ffac0580;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

#bnw-status-act{
    padding: 5px 3px;
    width: 120px;
    border-radius: 10px;
    background-color: rgba(5, 199, 5, 0.623);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

#status-col{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* background-color: purple; */
}

#bnw-tab-status_1{
    background-color: #ffac0580;
}

#bnw-tab-status_2{
    background-color: #ff2b0580;
}

/***************Director Container***************/

.bnw-director-container{
    width: 100%;
    min-height: 100vh;
    /* background-color: rgb(42, 165, 108); */
    position: relative;
}

/***************Agency Container***************/

.bnw-agency-container{
    width: 100%;
    min-height: 100vh;
    /* background-color: rgb(42, 165, 108); */
}

.bnw-agency-container_{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;   
}

.bnw-agency-container_ > span{
    font-size: 18px;
    font-weight: 600;
}

/***************Settings Container***************/

.bnw-settings-container{
    width: 100%;
    min-height: 100vh;
    background-color: rgb(42, 81, 165);
}

.bnw-settings-container_{
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.point-redemption-container{
    width: 100%;
    min-height: 300px;
    background-color: white;
    box-shadow: 0px 15px 35px rgba(168, 85, 247, 0.25);
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.point-redemption-container > div{
    display: flex;
    align-items: center;
    margin-bottom: 2px;
}

.redeem-point-row-1 > span{
    display: flex;
    align-items: center ;
}

.redeem-point-row-1 > span:first-child{
    margin-right: 10px;
}

.redeem-point-row-1 > span{
    font-size: 18px;
    font-weight: 500;
}

.point-redemption-container > div:nth-child(2){
    font-size: 20px;
    margin-bottom: 20px;
}

.point-redemption-container > div:nth-child(4){
    margin-bottom: 30px;
}

.point-redemption-container > div:nth-child(5){
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
    width: 70%;
    gap: 20px;
    /* padding: 0px 5px; */
}

.numberfield-container-main{
    width: 60%;
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
}

.numberfield-container-main>span{
    margin-top: 8px;
    margin-bottom: 15px;
}

#suitcase{
    color: white;
}

.numberfield-container{
    width: 100%;
    outline: none;
    display: flex;
    border: 1px solid rgba(136, 136, 136, 0.507);
}

.numberfield-container > div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
}

.numberfield{
    width: calc(100% - 50px);
    outline: none;
    border: none;
    padding: 10px;
    border-left: 1px solid rgba(136, 136, 136, 0.507);
}

.point-redemption-container > div:nth-child(5) > span{
    font-weight: 600;
    /* margin-right: 50px; */
    /* min-width: ; */
    width: 120px;
}

div > b{
    margin-right: 5px;
}

/***************Dashboard Container***************/
.bnw-body-left-botm{
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    /* align-items: center; */
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
    /* background-color: brown; */
    padding-right: 12px;
}

table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }
  
  table tr {
    border: 1px solid #ddd;
  }
  
  table th,
  table td {
    padding: .625em;
    font-size: 14px;
    font-weight: 500;
  }

  thead > tr{
    background-color: #e50000;
  }

  tr > th{
    color: white;
    border: 1px solid white;
  }

  tbody > tr:nth-child(even) {
    background-color: #194d9113;
  }
  
  table th {
    font-weight: 700;
    font-size: 15px;
  }
  
@media screen and (max-width: 900px) {
    table {
      border: 0;
    }
    
    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    
    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .625em;
    }
    
    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: 13px;
      text-align: right;
    }
    
    table td::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    
    table td:last-child {
      border-bottom: 0;
    }
}

@media screen and (max-width: 800px) {
    .bnw-body-right-container{
        display: none;
    }
    
    .bnw-body-container{
        /* background-color: brown; */
        flex-direction: column;
        /* overflow: auto; */
        padding-bottom: 20px;
    }

    .bnw-body-left{
        width: 100%;
        /* min-height: 100vh; */
        /* background-color: aqua; */
        margin-bottom: 15px;
    }

    .bnw-body-right{
        width: 100%;
        min-height: fit-content;
        /* background-color: rgb(217, 255, 0); */
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-left: 12px;
    }
}

@media screen and (max-width: 650px) {
    .point-redemption-container > div:nth-child(5){
        width: 100%;
    }

    .point-redemption-container > div:nth-child(5) > span{
        font-size: 13px;
        font-weight: 700;
    }
}

@media screen and (max-width: 600px){
    .booknwin-profile-container{
        width: 100%;
    }

    .register-new-user-tab > div:first-child > span{
        font-size: 11px;
        font-weight: 800;
    }

    #reg-submit-field{
        padding: 10px 0px;
        font-size: 14px;
        text-transform: uppercase;
    }
}

@media screen and (max-width: 500px) {
    .point-redemption-container > div:nth-child(3), .point-redemption-container > div:nth-child(4){
        font-size: 13px;
    }
    
    .point-redemption-container > div:nth-child(2){
        font-size: 15px;
    }
}

.single-selectt-container{
    position: relative;
    /* width: 100%; */
    /* margin-right: 5px; */
    border: 1px solid rgba(136, 136, 136, 0.212)
}

.bnw-firnamee-box{
    width: 100%;
    outline: none;
    border: 1px solid rgba(136, 136, 136, 0.103);
    padding: 10px;
    background-color: rgba(136, 136, 136, 0.103);
}

::placeholder{
    color: rgba(136, 136, 136, 0.795);
}

#chevron-down{
    position: absolute;
    top: 25%;
    right: 5%;
    cursor: pointer;
    color: rgba(136, 136, 136, 0.548);
}

.bnw-regionn-drpdwn, .bnw-deals-drpdwn{
    width: 100%;
    padding: 0px 0px 10px 0px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 0.1px solid rgba(0, 0, 0, 0.103);
    max-height: 150px;
    position: absolute;
    z-index: 100;
    top: 38px;
    overflow-y: scroll;
}

.bnw-regionn-drpdwn > span{
    width: 100%;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    background-color: white;
}

.bnw-regionn-drpdwn > span:hover{
    background-color: rgba(0, 0, 0, 0.192);
}
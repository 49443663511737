.landingpage-container{
    width: 100%;
    min-height: 100vh;
    display: flex;
    /* min-width: 400px; */
    flex-direction: column;
    gap: 20px;
}

.landpage-picture{
    width: 100%;
    height: 60vh;
    background: url('../../Assets/Images/sabre.jpg');
    background-position: center; 
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.landpage-picture-overlay{
    position: absolute;
    background-color: rgba(31, 30, 30, 0.514);
    width: 100%;
    height: 100%;
}

#how-to-participate{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px
}

#terms-and-conditions{
    width: 100%;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f8;
    padding: 20px 0px;
    /* background-color: gray; */
}

.terms-and-condition-list{
    width: 80%;
    min-width: 300px;
    /* background-color: blue; */
}

.terms-and-condition-list > li{
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: 500;
    list-style-type: decimal;
}

#terms-and-conditions > span:first-child{
    font-size: 22px;
    font-weight: 700;
    margin: 20px 0px;
    text-align: center;
}

#terms-and-conditions > span:nth-child(2){
    font-size: 16px;
    font-weight: 600;
    margin: 0px 0px 30px 0px;
    text-align: center;
}

.how-to-participate-top{
    width: 90%;
    height: 100%;
    display: flex;
    /* padding: 10px 0px; */
    gap: 30px;
    align-items: center;
}

.how-to-participate-image, .how-to-participate-image1{
    width: 60%;
    min-width: 200px;
    min-height: 300px;
    height: 100%;
    background: url('../../Assets/Images/land7.jpg');
    background-position: center; 
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    /* background-color: aqua; */
}

.how-to-participate-image1{
    background: url('../../Assets/Images/land1.jpg');
    background-size: cover;
}

.how-to-participate-text{
    width: 40%;
    min-width: 240px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background-color: aqua; */
}

.how-to-participate-text > span{
    margin: 20px 0px;
    font-size: 22px;
    font-weight: 700;
}

.htp-body, .htp-bodys{
    height: 50%;
}

.htp-bodys{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.htp-bodys > span{
    font-size: 15px;
}

.htp-body > li{
    list-style-type:decimal ;
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 500;
}

.htp-bodys > span:first-child{
    font-size: 16px;
}

#register-now{
    padding: 6px 16px;
    text-decoration: none;
    border: 1px solid rgba(136, 136, 136, 0.603);
    background-color: white;
    margin-top: 10px;
    margin-bottom: 30px;
    font-weight: 500;
}

@media screen and (max-width: 1000px) {
    .landpage-picture{
        height: 40vh;
        background-size: cover;
    }
}

@media screen and (max-width: 500px) {
    .how-to-participate-text > span{
        margin: 20px 0px;
        font-size: 20px;
        font-weight: 700;
    }

    .how-to-participate-image,.how-to-participate-image1{
        display: none;
    }

    .how-to-participate-text{
        width: 100%;
    }
}

.radiofield-container{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 15px;
    /* background-color: aqua; */
}

.custom-radio {
    left: -15px;
    top: 2px;
    cursor: pointer;
    width: 17px;
    height: 17px;
    border: 1px solid #9a9a9a;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    /* background-color: aqua; */
  }
  
  /* for inner filled circle */
  .custom-radio::after {
    content: '';
    width: 17px;
    height: 17px;
    background: #f4b400;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s;
  }
.bnw-registe-body{
    max-width: 350px;
    width: 90%;
    min-height: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(41 41 41 / 36%) 0px 3px 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 30px 0px;
    gap: 10px;
}

#bnw-phone-drpdwn{
    width: 80px;
    padding: 8px 10px;
    border: 1px solid rgba(0, 0, 0, 0.116);
    outline: none;
    font-weight: 500;
}

.bnw-registe-body > span:nth-child(2){
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}

.checkbox-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    /* background-color: aqua; */
    padding: 5px 15px;
    gap: 15px
}

.checkbox-container > span{
    font-size: 12px;
    font-weight: 500;
}


.dailcode-select-container{
    position: relative;
    width: fit-content;
    /* margin-right: 1px; */
}

#tel-chevron-down{
    position: absolute;
    top: 30%;
    right: 5%;
    cursor: pointer;
    color: rgba(136, 136, 136, 0.548);
    font-size: 15px;
}

.bnw-dailer-drpdwn{
    width: 100%;
    padding-left: 5px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 0.1px solid rgba(0, 0, 0, 0.103);
    max-height: 80px;
    position: absolute;
    z-index: 100;
    top: 38px;
    overflow-y: scroll;
}

.bnw-dailer-drpdwn > span{
    font-size: 13px;
    padding: 5px 0px;
    cursor: pointer;
}

.bnw-dailer-drpdwn > span:hover{
    background-color: #8888;
}

@media screen and (max-width: 400px) {
    .bnw-registe-body > span:nth-child(2){
        font-size: 16px;
        margin-bottom: 20px;
    }
}
.bnw-sidebar-container{
    width: 256px;
    height: fit-content;
    /* height: 100vh; */
    /* background-color: blue; */
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0px;
}

/*********Sidebar Button**********/
.bnw-sidbar-container, .active{
    width: 256px;
    /* background-color: aqua; */
    border: 1px solid rgba(0, 0, 0, 0);
}

.a{
    text-decoration: none;
    display: flex;
    align-items: center;
    /* width: 100%; */
    padding: 14px 15px;
    /* background-color: aqua; */
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

/* .active{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0);
    color: #2563EB;
} */

.bnw-sidbar-container:hover{
    border: 1px solid rgba(0, 0, 0, 0.027);
}

#bnw-sbc-icon{
    margin-left: 24px;
}

.bnw-sidbar-container > a > span:nth-child(1) > #bnw-sbc-mainicon{
    color: #DADEE5;
    width: 24px;
    height: 24px;
    /* background-color: aqua; */
}

.bnw-sidbar-container > a > span:nth-child(1){
    margin-left: 24px;
}

.bnw-sidbar-container > a > span:nth-child(2){
    margin-left: 24px;
    color: #606C80;
    font-weight: 700;
}

.bnw-sidbar-container > a > div{
    background-color: #e5000033;
    color: #e50000;
    padding: 5px 15px;
    border-radius: 20px;
    margin-left: 65px;
    font-size: 13px;
}

.active > a > span:nth-child(1) > #bnw-sbc-mainicon{
    width: 24px;
    height: 24px;
}

.active > a > span:nth-child(1){
    margin-left: 24px;
}

.active > a > span:nth-child(2){
    margin-left: 24px;
    font-weight: 700;
}

.active > a > div{
    background-color: #e5000033;
    color: #e50000;
    padding: 5px 15px;
    border-radius: 20px;
    margin-left: 65px;
    font-size: 13px;
}

#bnw-sbc-txt-active{
    margin-left: 24px;
}


/************Top SideBar*************/
.bnw-tpsbar-main-container{
    width: 100%;
    /* background-color: white; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.bnw-tpsbar-container{
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    /* background-color: aqua; */
    padding: 0px 10px;
}

.bnw-menu-Container{
    display: flex;
    align-items: center;
}

#bnw-himenu-icon{
    display: none;
}

#bnw-tsb-dashb{
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    color: #294566;
}

.bnw-profile{
    display: flex;
    align-items: center;
    /* background-color: brown; */
}

#bnw-profileImg{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: url("../../Assets/avatar/bases.jpg") #000 no-repeat; 
    background-position: center; 
    background-repeat: no-repeat;
    background-size: contain; 
}

.bnw-profile-name{
    display: flex;
    flex-direction: column;
    /* background-color: #2563EB; */
    min-width: 80px;
}

.bnw-profile-name > span:first-child{
    color: #294566;
    font-weight: 600;
}

.bnw-profile-name > span:last-child{
    color: #A3B6CC;
    font-weight: 500;
}

.bnw-searchbox-container{
    width: 35%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: blue; */
}

.bnw-profileImg-container{
    display: none;
}

@media screen and (max-width: 900px) {
    .bnw-searchbox-container{
        width: 65%;
        /* background-color: blue; */
    }
}

@media screen and (max-width: 800px) {
    .bnw-profileImg-container{
        height: 50px;
        width: 50px;
        border-radius: 50%;
        border: 1px solid rgba(0, 0, 0, 0.158);
        margin-right: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.bnw-search-box-contain{
    position: relative;
    width: 60%;
    min-width: 150px;
    /* background-color: aqua; */
    display: flex;
}

@media screen and (max-width: 550px) {
    .bnw-search-box-contain{
        display: none;
    }

    .bnw-searchbox-container{
        justify-content: flex-end;
        /* background-color: blue; */
        width: fit-content;
    }

    #bnw-tsb-dashb{
        display: none;
    }
}

#bnw-search-box{
    width: 100%;
    padding: 10px 15px 10px 40px;
    outline: none;
    /* border: 1px solid rgba(0, 0, 0, 0.068); */
    border: none;
    border-radius: 20px;
    letter-spacing: 1px;
    background-color: transparent;
    /* background-color: aqua; */
}

#bnw-search-icon{
    position: absolute;
    font-size: 20px;
    left: 3%;
    top: 10px;
    color: #98A2B2;
}


@media screen and (max-width: 1000px){
    .bnw-sidebar-container{
        width: 0px;
        z-index: -10;
        position: absolute;
    }

    .bnw-sidebar-container-clicked{
        z-index: 5;
        background-color: #F7F8FA;
        position: absolute;
        width: 256px;
        height: 100%;
        display: flex;
        flex-direction: column;
        top: 0px;
        transition: z-index 0.5s ease-in-out;
        border-right: 1px solid rgba(0, 0, 0, 0.055);
    }

    #bnw-himenu-icon{
        display: block;
        margin-right: 20px;
        width: 25px;
        height: 25px;
    }

    
}


.navbarContian{
    width: 100%;
    height: 50px;
    /* position: relative; */
    position: sticky;
    top: 0;
    background-color: black;
    top: 0px;
    z-index: 10;
    display: flex;
    align-items: center;
    min-width: 320px;
    /* justify-content: space-between; */
}

.navbarcontain-left{
    width: 46%;
    height: 100%;
    align-items: center;
    display: flex;
    flex-grow: 1;
    /* background-color: blue; */
}

.navbarcontain-right{
    width: fit-content;
    /* min-width: 400px; */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
    gap: 15px;
    /* background-color: gray; */
}

.navbarcontain-right > li{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    cursor: pointer;
    font-weight: 500;
}

.navbar_language{
    display: none;
}

#myidlink{
    text-decoration: none;
    color: #8d939c;
    height: 100%;
    display: flex;
    padding: 5px 0px;
    align-items: center;
    justify-content: center;
    /* background-color: aqua; */
}

#myidlink:hover{
    color: white;
}

#my-idlink{
    text-decoration: none;
    color: black;
    height: 100%;
    width: 100%;
    display: flex;
    /* padding: 5px 15px; */
    align-items: center;
    justify-content: center;
    /* background-color: aqua; */
}

#imgIcon{
    background: url('../../Assets/Images/sabre.png') no-repeat;
    background-position: center; 
    background-repeat: no-repeat;
    background-size: cover; 
    height: 100%;
    width: 100% ;
    cursor: pointer;
}

.navbar-icon{
    width: 130px;
    height: 100%;
    /* background-color: aqua; */
    display: flex;
    align-items: center;
}

.navbarcontain-left > span{
    margin-left: 50px;
    font-weight: 400;
    /* color: #475366; */
    color: white;
    font-size: 23px;
    word-spacing: 2px;
    letter-spacing: 1px;
    font-weight: 600;
}

#landpg-menubar{
    font-size: 25px;
    margin-right: 20px;
    cursor: pointer;
    display: none;
    color: white;
}

.hover-container{
    width: 100%;
    height: fit-content;
    background-color: white;
    position: absolute;
    top: 50px;
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding: 10px;
    min-width: 320px;
}

.hover-container > li{
    width: 100%;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
}

@media screen and (max-width: 900px) {
    .navbarcontain-left{
        width: 100%;
    }
    
    .navbarcontain-right{
        display: none;
    }

    .navbar_language{
        width: fit-content;
        /* min-width: 400px; */
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 15px;
        gap: 15px;
        /* background-color: gray; */
    }
    
    .navbar_language > li{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        list-style-type: none;
        cursor: pointer;
        font-weight: 500;
    }

    #landpg-menubar{
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .lang_text{
        display: none;
    }
}

@media screen and (max-width: 550px) {
    .navbarcontain-left > span{
        display: none;
    }
}

/********* Claim Cards **********/

.bnw-claimcard-container{
    width: 31%;
    min-width: 200px;
    min-height: 200px;
    border-radius: 10px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 15px 35px rgba(59, 130, 246, 0.2);
    padding: 30px;
    padding-bottom: 10px;
}

/* .bnw-active-claim-container{
    width: 100%;
    margin-bottom: 20px;
} */

#bnw-active-claim, #bnw-active-claim-reg, #bnw-active-claim-age{
    font-size: 17px;
    font-weight: 800;
    color: #323C4D;
    margin-bottom: 10px;
    text-align: center;
}

#bnw-active-claim-reg{
    margin-bottom: 50px;
}

.bnw-active-claim-chat-1{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    color: transparent;
}

@keyframes spin {
    to {transform: rotate(.5turn);}
}

@keyframes bg {
    50% {background: #e50000;}
}

.bnw-active-claim-chat-1::before{
    content: '';
    width: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    height: 100%;
    border-radius: 0 100% 100% 0 / 50%; 
    background-color: inherit;
    transform-origin: left;
    animation: spin 50s linear infinite, bg 100s step-end infinite;
    animation-play-state: paused;
    animation-delay: inherit;
}

.bnw-active-claim-chat-2{
    position: absolute;
    width: 140px;
    height: 140px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 136, 0, 0.295);
}

.currency-conv-container{
    width: 100%;
    display: flex;
    /* background-color: blanchedalmond; */
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding: 5px 0px;
}

.currency-conv-container > span{
    font-size: 14px;
    font-weight: 600;
}

#bnw-active-claim-numb{
    font-weight: 700;
    font-size: 20px;
    color: #323C4D;
}

/* .bnw-claimcard-container:last-child{
    margin-right: 0px;
} */

/*********** Location Container *************/

.bnw-locatecard-container{
    width: 100%;
    min-width: 160px;
    min-height: 200px;
    border-radius: 10px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    box-shadow: 0px 15px 35px rgba(168, 85, 247, 0.25);
    margin-top: 10px;
    /* justify-content: center; */
    align-items: center;
    padding: 30px;
    padding-bottom: 10px;
}

/*********** Region Container *************/

.bnw-regioncard-container{
    width: 100%;
    min-width: 160px;
    min-height: 400px;
    border-radius: 10px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    box-shadow: 0px 15px 35px rgba(168, 85, 247, 0.25);
    margin-top: 30px;
    padding: 50px 30px;
    background: #FFF0E8;
}


/*********** Sales Funnel *************/
.bnw-sfunnelcard-container{
    /* width: 100%; */
    min-width: 200px;
    max-height: 400px;
    border-radius: 10px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    box-shadow: 0px 15px 35px rgba(168, 85, 247, 0.25);
    margin-top: 30px;
    margin-right: 12px;
    background: #FFFFFF;
    padding: 30px;
}

.bnw-salesfun-text-contain{
    width: 100%;
    /* background-color: aqua; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

#bnw-salesfun-text, #bnw-salesfun-mon{
    font-size: 20px;
    font-weight: 800;
    color: #323C4D;
}

.bnw-salesfun-linegraph{
    width: 100%;
    min-height: 90%;
}

/***********Top Performing Agencies *********/
.bnw-tpacard-container{
    width: 100%;
    min-width: 160px;
    min-height: 350px;
    border-radius: 10px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    box-shadow: 0px 15px 35px rgba(168, 85, 247, 0.25);
    margin-top: 20px;
    background: #FEFFE3;
    padding: 30px;
}

#bnw-tpacard-text{
    font-size: 17px;
    font-weight: 800;
    color: #323C4D;
    margin-bottom: 20px;
    text-align: center;
}

/****************Current Region Sales*********************/
.bnw-crsscard-container{
    min-width: 200px;
    min-height: 350px;
    border-radius: 10px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 15px 35px rgba(168, 85, 247, 0.25);
    /* margin-top: 20px; */
    margin-right: 12px;
    padding: 30px;
}

#bnw-crsscard-text{
    font-size: 17px;
    font-weight: 800;
    color: #323C4D;
    margin-bottom: 40px;
    text-align: center;
}

.bnw-active-crss-chat-1{
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: transparent;
    text-align: center;
}

@keyframes spin {
    to {transform: rotate(.5turn);}
}

@keyframes bg {
    50% {background: #e50000;}
}

.bnw-active-crss-chat-1::before{
    content: '';
    width: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    height: 100%;
    border-radius: 0 100% 100% 0 / 50%; 
    background-color: inherit;
    transform-origin: left;
    animation: spin 50s linear infinite, bg 100s step-end infinite;
    animation-play-state: paused;
    animation-delay: inherit;
}


.bnw-active-crss-chat-2{
    position: absolute;
    width: 190px;
    height: 190px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 136, 0, 0.295);
}

/*******************Top Performing Region***********************/
.bnw-tpregcard-container{
    min-width: 200px;
    min-height: 350px;
    border-radius: 10px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    box-shadow: 0px 15px 35px rgba(168, 85, 247, 0.25);
    /* margin-top: 20px; */
    background: #FFFFFF;
    /* margin-right: 12px; */
    padding: 30px;
}

/***************Travel Cards***************/
.bnw-travels-content{
    width: 100%;
    padding: 5px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: blue; */
}

.bnw-travels-left{
    width: 80%;
    min-width: 80px;
    display: flex;
    align-items: center;
}

.bnw-travels-left > span{
    font-weight: 600;
    font-size: 15px;
}

#bnw-travels-img{
    height:  40px;
    width: 40px;
    background-color: rgba(0, 0, 0, 0.404);
    margin-right: 15px;
    border-radius: 50%;
    /* background: url("../../../public/avaters/1.jpg") #000 no-repeat; */
    background-position: center; 
    background-repeat: no-repeat;
    background-size: contain;
}

.bnw-travels-content > span{
    font-weight: 700;
    font-size: 18px;
}

@media screen and (max-width: 750px) {
    .bnw-locatecard-container{
        width: 30%;
        min-width: 200px;
        min-height: 300px;
        margin-right: 12px;
        margin-top: 0;
        margin-bottom: 15px;
    }
    
    .bnw-regioncard-container{
        width: 30%;
        min-width: 200px;
        min-height: 300px;
        margin-top: 0;
        margin-right: 12px;
        margin-bottom: 15px;
        padding: 30px;
    }

    .bnw-tpacard-container{
        width: 30%;
        min-width: 200px;
        min-height: 300px;
        margin-top: 0;
        margin-bottom: 15px;
    }

    #bnw-active-claim-reg, #bnw-active-claim-age{
        margin-bottom: 30px;
    }
    
}

@media screen and (max-width: 623px) {
    .bnw-regioncard-container{
        margin-right: 0px;
    }
}

/* @media screen and (max-width: 550px) {
    .bnw-regioncard-container{
        margin-right: 0px;
    }

    .bnw-tpregcard-container{
        margin-top: 20px;
    }
}

@media screen and (max-width: 527px) {
    .bnw-crsscard-container{
        margin-right: 0px;
    }

    .bnw-tpregcard-container{
        margin-left: 0px;
    }
}

@media screen and (max-width: 414px) {
    .bnw-locatecard-container{
        margin-right: 0px;
    }

    .bnw-regioncard-container{
        margin-right: 12px;
    }
} */
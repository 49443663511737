.footerContainerSup{
    width: 100%;
    /* min-width: 400px; */
    height: 50px;
    background-color: white;
    /* background-color: aqua; */
    display: flex;
    justify-content: center;
    align-items: center;
}

#serverError{
    width: 150px;
    height: 90px;
    /* background: url('../../Assets/Images/servererror.svg'); */
    background-position: center; 
    background-repeat: no-repeat;
    background-size: contain; 
    color: #888;
}

.bnw-logout{
    text-decoration: none;
    color: white;
}


.bnw-logout:hover{
    text-decoration: none;
    color: #e50000;
}

#bnw-logout-button{
    font-size: 15px;
    padding: 5px 15px;
    font-weight: 600;
    color: white;
    background-color: #e50000;
    outline: none;
    border: 1px solid #e50000;
    margin-top: 10px;
    cursor: pointer;
}

#bnw-logout-button:hover{
    background-color: white;
    color: #e50000;
}

#errorInfo, #conf-message-Info{
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin-top: 20px;
}

#conf-message-Info{
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 5px;
}

#conf-message-Info > p{
    color: blue;
    cursor: pointer;
}

#conf-message-Info > p:hover{
    text-decoration: underline;
    color: red;
}

.footerContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}

#footer-text{
    text-align: center;
    font-size: 13px;
}

#footer-text > b{
    color: #e50000;
}

.bnw-confirm-pg{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.342);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
}

.bnw-confirm-cont{
    width: 300px;
    height: 300px;
    background-color: white;
    padding: 40px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bnw-confirm-head{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    /* background-color: aqua; */
    margin-bottom: 60px;
}

.bnw-confirm-head > span{
    text-align: center;
    font-weight: 700;
    font-size: 25px;
    word-spacing: 2px;
    color: rgba(51, 51, 51, 0.842);
}

#bnw-heads{
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    word-spacing: 2px;
    color: rgba(51, 51, 51, 0.842);
}

#bnw-conf-icon{
    font-size: 30px;
    color: rgba(51, 51, 51, 0.842);
    cursor: pointer;
    position: absolute;
    right: 0px;
}

.bnw-confm-but-contain{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#bnw-approve-but, #bnw-decline-but{
    font-size: 15px;
    font-weight: 600;
    color: white;
    background-color: rgba(0, 128, 0, 0.781);
    padding: 5px 15px;
    outline: none;
    border: 1px solid rgba(0, 128, 0, 0.781);
    cursor: pointer;
}

#bnw-decline-but{
    background-color: #e50000;
    border: 1px solid #e50000;
}

#bnw-approve-but:hover{
    color: rgba(0, 128, 0, 0.781);
    background-color: white;
}

#bnw-decline-but:hover{
    color: #e50000;
    background-color: white;
}

.errorContainer{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.514);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    top: 0;
}

.errorSubContain, .confirmSubContain{
    background-color: white;
    width: 350px;
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgb(41 41 41 / 36%) 0px 3px 6px;
}

.confirmSubContain{
    height: 30vh;
}

.ClsiconContain, .conf-ClsiconContain{
    width: 90%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
}

.conf-ClsiconContain{
    margin-bottom: 10px;
    margin-top: 10px;
}



#closeIcon{
    font-size: 30px;
    color: rgba(0, 0, 0, 0.747);
    cursor: pointer;
}

#confIcon{
    width: 80px;
    height: 80px;
    color: green;
}

#confirmfIcon{
    width: 30px;
    height: 30px;
    color: green;
}

#errorIcon{
    width: 80px;
    height: 80px;
    color: #e50000;
}

/* @media screen and (max-width: 560px) {
    .footerContainerSup{
        display: none;
    }
} */
